import { ReactComponent as InfoIcon } from '../../../assets/vectors/info.svg';
import styles from './warningBanner.module.scss';

export interface WarningBannerProps {
  /**
   * The message to display in the warning banner
   */
  message: string | React.ReactNode;
  /**
   * Optional className to override default styles
   */
  className?: string;
  /**
   * Optional icon to override the default info icon
   */
  icon?: React.ReactNode;
  /**
   * Optional maximum width for the banner. Defaults to 800px.
   */
  maxWidth?: number;
}

const WarningBanner = ({
  message,
  className,
  icon = <InfoIcon className={styles.warningIcon} />,
  maxWidth = 800,
}: WarningBannerProps) => {
  return (
    <div className={styles.warningContainer}>
      <div
        className={`${styles.warning} ${className || ''}`}
        style={{ maxWidth: `${maxWidth}px` }}
      >
        {icon}
        <span>{message}</span>
      </div>
    </div>
  );
};

export default WarningBanner;
