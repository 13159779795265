import { useAppSelector } from '../../../redux/hooks';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { FlowStep } from '../../../types/stepFlow.type';
import FormOnlyStepContainer from '../../step-flow/FormOnlyStep';
import * as Yup from 'yup';
import styles from './analysisSetupFlow.module.scss';
import { StepContent } from '../../step-flow/CreateStepContent';
import { updateAnalysisSetupFlowStep } from '../../../redux/slices/analyzeTrial';
import FormWithCheckboxForExtraFieldsStep from '../../step-flow/FormWithCheckboxForExtraFieldsStep';
import TreatmentReviewStepContainer from '../TreatmentReviewStep';
import AnalysisSummaryStep from '../AnalysisSummaryStep';

const AnalysisSetupFlow = () => {
  const trialDesign = useAppSelector((state) => state.analyzeTrial.trialDesign);
  const [searchParams] = useSearchParams();
  const [analysisId] = useState<string | null>(searchParams.get('id'));
  const [savedAnalysis, setSavedAnalysis] = useState<any>(null);
  const fieldResults = useAppSelector(
    (state) => state.analyzeTrial.fieldResults,
  );
  const analyses = useAppSelector((state) => state.analyzeTrial.analyses);
  const analysisSetupInProgress = useAppSelector(
    (state) => state.analyzeTrial.analysisSetupFlow.inProgress,
  );

  const MatchAnalysisId = useCallback(() => {
    for (let i = 0; i < analyses.length; i++) {
      if (analyses[i].analysis_id === analysisId) {
        return analyses[i];
      }
    }
    return null;
  }, [analyses, analysisId]);

  useEffect(() => {
    if (analysisId) {
      setSavedAnalysis(MatchAnalysisId());
    }
  }, [analysisId, MatchAnalysisId]);

  // split crop yield rate units to get the unit of the crop itself, pair it with $ for crop value
  const crop_value_unit = `$/${
    (fieldResults[0]?.yield_data?.rate_units || 'bu/ac').split('/')[0]
  }`;

  const product_unit = fieldResults[0]?.as_applied_data?.rate_units
    ? fieldResults[0].as_applied_data.rate_units?.split('/')[0]
    : trialDesign.application_units
    ? trialDesign.application_units?.split('/')[0]
    : 'gal';

  const twoSidedYieldDiff = [
    'Planting',
    'Fertilizer',
    'Nitrogen Stabilizer',
  ].includes(trialDesign.planning_trial_type);

  const analysisSetupFlowSteps: FlowStep[] = [
    {
      container: FormOnlyStepContainer,
      heading: 'Crop, variety, and price',
      props: {
        formElements: [
          {
            label: 'Crop:',
            name: 'analysis_crop',
          },
          {
            label: 'Variety:',
            name: 'analysis_variety',
          },
          {
            element: 'horizontalDivider',
          },
          {
            label: 'Harvested Crop Sales Price:',
            name: 'crop_price_dollars_per_crop_unit',
            rightElement: crop_value_unit,
          },
        ],
        initialValues: {
          analysis_crop:
            savedAnalysis?.analysis_crop || trialDesign?.crop || '',
          analysis_variety:
            savedAnalysis?.analysis_variety || trialDesign.variety || '',
          crop_price_dollars_per_crop_unit:
            savedAnalysis?.crop_price_dollars_per_crop_unit || '',
        },
        uniqueKey: 'crop-variety-price',
        validationSchema: Yup.object({
          analysis_crop: Yup.string().required('Crop is required'),
          crop_price_dollars_per_crop_unit: Yup.number()
            .typeError('Harvested crop sales price must be a number')
            .required('Harvested crop sales price is required'),
        }),
      },
    },
    {
      container: FormWithCheckboxForExtraFieldsStep,
      heading: 'Confirm product and price',
      props: {
        alwaysFormElements: [
          {
            label: 'Product:',
            name: 'product_name',
          },
          {
            label: 'Price:',
            name: 'product_price_dollars_per_gal',
            rightElement: `$/${product_unit}`,
          },
        ],
        initialValues: {
          application_price_dollars_per_acre:
            savedAnalysis?.products[0].application_price_dollars_per_acre || '',
          extra_pass: savedAnalysis?.products[0].extra_pass || false,
          is_tank_mix: savedAnalysis?.products[0].is_tank_mix || false,
          product_name:
            savedAnalysis?.products[0].product_name ||
            trialDesign?.product ||
            '',
          product_price_dollars_per_gal:
            savedAnalysis?.products[0].product_price_dollars_per_gal || '',
          tank_mix_description:
            savedAnalysis?.products[0].tank_mix_description || '',
          tank_mix_percentage:
            savedAnalysis?.products[0].tank_mix_percentage || '',
        },
        optionalGroups: [
          {
            checkboxElement: {
              element: 'checkbox',
              label: 'This is in a tank mix',
              name: 'is_tank_mix',
            },
            ifCheckedFormElements: [
              {
                element: 'input',
                label: 'Tank mix description:',
                name: 'tank_mix_description',
                type: 'text',
              },
              {
                element: 'input',
                label: '% of product in tank:',
                name: 'tank_mix_percentage',
                type: 'number',
              },
            ],
          },
          {
            checkboxElement: {
              element: 'checkbox',
              label: 'Extra machine pass required for this application',
              name: 'extra_pass',
            },
            ifCheckedFormElements: [
              {
                element: 'input',
                label: 'Application price:',
                name: 'application_price_dollars_per_acre',
                type: 'number',
                rightElement: '$/acre',
              },
            ],
          },
        ],
        uniqueKey: 'confirm-product-price',
        validationSchema: Yup.object({
          product_name: Yup.string().required('Product is required'),
          product_price_dollars_per_gal: Yup.number()
            .typeError('Product price must be a number')
            .required('Product price is required'),
          // todo: add complex validation for conditional fields
        }),
      },
    },
    {
      container: TreatmentReviewStepContainer,
      heading: 'Treatments Review',
      props: {
        productName: trialDesign.product,
        treatmentRates: trialDesign.treatment_rates,
        treatmentLabels: trialDesign.treatment_labels,
        nominalRate: trialDesign.nominal_rate,
        nominalLabel: trialDesign.nominal_label || '',
        rateUnits: trialDesign.application_units,
        analysisTreatmentOverride:
          savedAnalysis?.trial_design_overrides.find(
            (override: Record<string, any>) =>
              override.trial_design_field_name === 'treatments',
          ) || [],
        uniqueKey: 'treatments-review',
      },
    },
    {
      container: FormOnlyStepContainer,
      heading: 'Advanced Settings',
      props: {
        formElements: [
          {
            element: 'radioGroup',
            name: 'two_sided_yield_diff',
            options: [
              {
                label: 'Two-sided yield difference',
                value: true,
                tooltip:
                  'Used for trials where the test condition can either raise or ' +
                  'lower the yield. This is typically the case when changing seeding rate or ' +
                  'variety, or replacing some fertilizer with a microbial fertilizer.',
              },
              {
                label: 'One-sided yield difference',
                value: false,
                tooltip:
                  'Used for trials where the test condition can only raise ' +
                  'the yield. This is typically the case for pest control passes, extra nutrients' +
                  ', or adding additional microbial fertilizer. ' +
                  'In these cases, a one-sided yield difference can have a smaller LSD.',
              },
            ],
            radioGroupFooter: (
              <div>
                Based on the test variable of your trial (i.e. ‘seeding rate’ vs
                ‘fungicide’) we infer whether your trial has a one-sided or
                two-sided yield difference, but please confirm. We need this to
                calculate the <b>Least Significant Difference (LSD).</b>
              </div>
            ),
            radioGroupHeader: 'One-sided or two-sided yield difference',
            value: savedAnalysis?.two_sided_yield_diff || twoSidedYieldDiff,
          },
          {
            element: 'horizontalDivider',
          },
          {
            element: 'horizontalInputPair',
            elements: [
              {
                label: 'False positive rate:',
                name: 'false_positive_rate',
                rightElement: '%',
                type: 'number',
                tooltip: `False positive rate is the likelihood that if a management practice 
                  being tested has no real effect, we will measure an average yield or net value
                  difference that is greater than the Least Significant Difference (LSD) because of
                  inherent random yield variability. 
                  When this happens, i.e. a measured yield or net value difference is caused by random
                  yield variability only, we expect multiple or repeated measurements to produce some 
                  average yield or net value differences above the LSD (roughly the false positive rate, e.g. 10%)
                  and some below the LSD (e.g. roughly 90% = 100% - 10%).
                  The risk of setting the false positive rate too low is that the false negative
                  rate (the likelihood of concluding that a management practice has no effect
                  when in reality it does) increases as the false positive rate decreases.`,
              },
              {
                element: 'explanatoryParagraphs',
                paragraphTexts: [
                  `University researchers typically use 5%. For research on most commercial
                  farms, 10%, 15%, or 20% is more practical.`,
                ],
              },
            ],
          },
          ...(trialDesign.planning_trial_type === 'Fertilizer'
            ? [
                {
                  element: 'horizontalDivider',
                },
                {
                  label: 'Static rate application to the whole field:',
                  name: 'static_rate',
                  type: 'number',
                  tooltip: `If you applied additional product to the whole field as a static rate, 
                            in addition to the prescription, please enter that rate here in the same
                            units as the prescription (${fieldResults[0]?.as_applied_data?.rate_units}).
                            
                            Note that this is not your nominal rate - that's already accounted for
                            in the prescription, if it differs from your treatment rates. Additional
                            static rates are most commonly "rescue applications", where some product
                            was washed out by rain and you elected to apply more later in the season
                            in order to recover the field.`,
                  rightElement: fieldResults[0]?.as_applied_data?.rate_units,
                },
              ]
            : []),
        ],
        initialValues: {
          false_positive_rate: savedAnalysis?.false_positive_rate || 10,
          two_sided_yield_diff:
            savedAnalysis?.two_sided_yield_diff || twoSidedYieldDiff,
          ...(trialDesign.planning_trial_type === 'Fertilizer'
            ? {
                static_rate: savedAnalysis?.static_rate || '',
              }
            : {}),
        },
        uniqueKey: 'advanced-settings',
        validationSchema: Yup.object({
          false_positive_rate: Yup.number()
            .typeError('False positive rate must be a number')
            .required('False positive rate is required'),
          ...(trialDesign.planning_trial_type === 'Fertilizer'
            ? {
                static_rate: Yup.number().typeError(
                  'Static rate must be a number',
                ),
              }
            : {}),
        }),
      },
    },
    {
      container: AnalysisSummaryStep,
      heading: 'Analyze Trial: Review and Save',
      props: {
        cropValueUnits: crop_value_unit,
        analysisId: analysisId,
        inProgressExecution: analysisSetupInProgress,
        trialId: trialDesign.id,
        uniqueKey: 'analysis-summary',
        trialDesign: trialDesign,
      },
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const { heading, subheading, container, props } =
    analysisSetupFlowSteps[activeIndex];

  if (Object.keys(trialDesign).length === 0) {
    return (
      <div
        data-testid="analysis-setup-flow-trial-design-missing-error"
        className={styles['analysis-setup-flow-error']}
      >
        <p>
          <b>The current page was loaded without a trial design.</b>
        </p>
        <p>
          This is probably because the URL was loaded directly, rather than from
          an Analysis & Results page. Please navigate from your list of field
          trials to view the analysis and results for a specific trial; from
          there you may click "Configure Analysis" to correctly load this page.
        </p>
        <p>
          If you came here from a link elsewhere on the site, please contact
          FarmTest for assistance.
        </p>
      </div>
    );
  }

  return (
    <StepContent
      heading={heading}
      subheading={subheading}
      superheading={`Analyze trial`}
      Container={container}
      props={props}
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
      updateStateFunction={updateAnalysisSetupFlowStep}
      stateSelector={(state) => state.analyzeTrial.analysisSetupFlow}
      flowSteps={analysisSetupFlowSteps}
    />
  );
};

export default AnalysisSetupFlow;
