import { FieldTrial } from '../../../types/workspace.type';
import {
  Analysis,
  FieldData,
  FieldResult,
} from '../../../types/analyzeTrial.type';
import notYetProvided from '../../../assets/images/not-yet-provided.png';
import notYetGenerated from '../../../assets/images/not-yet-generated.png';
import loadingImage from '../../../assets/images/loading-image.png';
import Table from '../../global/Table';
import { useEffect, useState } from 'react';
import { Column } from 'react-table';
import styles from './analysisLandingContent.module.scss';
import Spinner from '../../global/Spinner';
import Button from '../../global/Button';
import { titleCase } from '../../../utils/helpers/capitalizationUtils';
import { Routes } from 'types/global.type';
import Tooltip from '../../global/Tooltip';
import { ReactComponent as InfoIcon } from 'assets/vectors/info.svg';
import { timestampToDate } from '../../../utils/helpers/analyzeTrial';
import {
  useRequestReportMutation,
  useFetchDesignImageQuery,
} from '../../../redux/services/analysis';
import { Link } from 'react-router-dom';
import ImagePreview from '../../create-trial/ImagePreview';

interface AnalysisLandingContentProps {
  trialDesign: FieldTrial | undefined;
  analyses: Analysis[];
  fieldResults: FieldResult[];
  trialDesignIsLoading: boolean;
  analysesIsLoading: boolean;
  fieldResultsIsLoading: boolean;
}

interface AnalysisLandingTableProps {
  data: Analysis[] | FieldData[] | FieldTrial[];
  columns: Column<any>[];
  tableHeader: string;
  isLoading: boolean;
  imageUrl?: string;
  imageIsNonSquare?: boolean;
  isRequested: boolean;
  onRowSelect?: (row: any) => void;
}

interface StepFlowButtonProps {
  destinationRoute: string;
  buttonText: string;
  recordId?: string;
  isDisabled?: boolean;
  disabledTooltip?: string;
}

const mergeTreatmentLabelsWithRates = (
  treatmentLabels: string,
  treatmentRates: string,
  application_units: string | null,
  nominal_rate: string,
  nominal_label: string,
) => {
  // Create a string of the form `a: 10 gal/ac, b: 20 gal/ac`, etc.
  // If there are no units, they'll be omitted. If there are no labels, again,
  // they'll be omitted and we'll return "10 gal/ac, 20 gal/ac" etc.
  // Nominal rate, if present, will be prepended to the list of rates, with
  // "Nominal" as the label if none is present.
  const rates = treatmentRates.split(',');
  const labels =
    treatmentLabels !== 'Use rates'
      ? treatmentLabels.split(',')
      : new Array(rates.length).fill(null);

  // use empty string if the trial design does not have an application_units value
  const units = application_units ?? '';

  if (nominal_rate !== undefined && nominal_rate !== '') {
    rates.unshift(nominal_rate);
    labels.unshift(nominal_label ? nominal_label : 'Nominal');
  }

  return labels
    .map((label, index) => {
      if (label === null && units === null) {
        return `${rates[index]}`;
      } else if (label === null) {
        return `${rates[index]} ${units}`;
      } else if (units === null) {
        return `${label}: ${rates[index]}`;
      } else {
        return `${label}: ${rates[index]} ${units}`;
      }
    })
    .join(', ');
};

const getUnitCostText = (
  unitCost: number,
  unitsPerAc: string | undefined,
  costType: string,
) => {
  if (unitsPerAc === undefined) return `$${unitCost} per ${costType} unit`;
  const baseUnit = unitsPerAc.split('/')[0];
  return `$${unitCost}/${baseUnit}`;
};

const getTrialColumns = (application_units: string | null): Column<any>[] => [
  {
    Header: 'Field Name',
    accessor: (row) => row.field.field_name,
  },
  {
    Header: 'Farm Name',
    accessor: (row) => row.field.farm_name,
  },
  {
    Header: 'Crop',
    accessor: (row) =>
      row?.variety ? `${row.crop} (${row.variety})` : row?.crop,
  },
  {
    Header: 'Start Date',
    accessor: (row) => new Date(row.start_date).toLocaleDateString(),
  },
  {
    Header: 'End Date',
    accessor: (row) => new Date(row.end_date).toLocaleDateString(),
  },
  {
    Header: 'Treatment Rates',
    accessor: (row) => {
      return mergeTreatmentLabelsWithRates(
        row.treatment_labels,
        row.treatment_rates,
        application_units,
        row?.nominal_rate,
        row?.nominal_label,
      );
    },
  },
  {
    Header: 'Field Area',
    accessor: (row) => `${row.field_area} ac`,
  },
];

const getFieldResultColumns = (
  resultData: FieldData[],
  readableDataType: string,
  stepFlowRoute: string,
  existingFieldResultId?: string,
  isRequested?: boolean,
): Column<any>[] => {
  if (resultData.length === 0) {
    return [
      {
        Header: ' ',
        id: 'noData',
        accessor: (row) =>
          `You have not yet provided ${readableDataType} data for this trial.`,
      },
      {
        Header: ' ',
        id: 'createNewData',
        accessor: (row) => (
          <StepFlowButton
            destinationRoute={stepFlowRoute}
            buttonText={`Upload ${titleCase(readableDataType)} Data`}
            recordId={existingFieldResultId}
          />
        ),
      },
    ];
  } else {
    const columns: Column<any>[] = [
      {
        Header: `${
          readableDataType === 'yield' ? 'Harvest Date' : 'Planting Date'
        }`,
        accessor: (row) =>
          row?.harvest_date
            ? timestampToDate(row.harvest_date)
            : timestampToDate(row.planting_date),
      },
      {
        Header: 'Shapefile',
        accessor: (row) => (
          <a
            href={row.current_shapefile?.file_data?.signed_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download
          </a>
        ),
      },
      {
        Header: 'Upload Date',
        accessor: (row) =>
          timestampToDate(row.current_shapefile.upload_date || ''),
      },
      {
        Header: `${
          readableDataType === 'yield' ? titleCase(readableDataType) : 'Rate'
        } Column`,

        accessor: (row) =>
          `${row?.rate_column || 'Not Set'} ${
            row?.rate_units ? ' (' + row.rate_units + ')' : ''
          }`,
      },
      {
        Header: 'Reps to Remove',
        accessor: (row) => row.reps_to_remove?.join(', '),
      },
      {
        Header: ' ',
        accessor: (row) => (
          <StepFlowButton
            destinationRoute={stepFlowRoute}
            buttonText={`Edit ${titleCase(readableDataType)} Data`}
            recordId={row.field_result_id}
            isDisabled={isRequested}
            disabledTooltip={
              isRequested
                ? 'This data has already been submitted for analysis and cannot be edited.'
                : undefined
            }
          />
        ),
      },
    ];
    if (readableDataType === 'as-applied' && resultData[0]?.application_date) {
      columns.splice(1, 0, {
        Header: 'Application Date',
        accessor: (row) => timestampToDate(row.application_date),
      });
    }
    return columns;
  }
};

const getRequestReportButton = (
  analysisId: string,
  requestReportCallback: (data: any) => void,
  isLoading: boolean,
) => {
  const requestButton: Column<any> = {
    Header: ' ',
    id: 'submitData',
    accessor: (row) => (
      <Button
        action="button"
        variant="brand"
        className={`${styles['analysis-landing-table__button']}`}
        isLoading={isLoading}
        onClick={() => {
          requestReportCallback(analysisId);
        }}
      >
        {isLoading ? <Spinner /> : 'Request Report'}
      </Button>
    ),
  };
  return requestButton;
};

const getAnalysisColumns = (
  analyses: Analysis[],
  cropUnits: string | undefined,
  productUnits: string | undefined,
  cropName: string,
  requestReportButton: Column<any>,
  isRequested?: boolean,
  canRequestReport?: boolean,
  reportIsReady?: boolean,
  analysisDataAvailable?: boolean,
): Column<any>[] => {
  if (analyses.length === 0) {
    return [
      {
        Header: ' ',
        id: 'noData',
        accessor: (row) =>
          `You have not yet configured the analysis for this trial.`,
      },
      {
        Header: ' ',
        id: 'createNewData',
        accessor: (row) => (
          <StepFlowButton
            destinationRoute="/analyze-trial/analysis-setup-flow"
            buttonText={'Configure Analysis'}
          />
        ),
      },
    ];
  } else {
    let buttonColumns: Column<any>[] = [];
    const baseColumns: Column<any>[] = [
      {
        Header: `${titleCase(cropName)} Price`,
        accessor: (row) =>
          getUnitCostText(
            row.crop_price_dollars_per_crop_unit,
            cropUnits,
            'crop',
          ),
      },
      {
        Header: 'Product',
        accessor: (row) =>
          `${row?.products?.[0]?.product_name} at ${getUnitCostText(
            row?.products?.[0]?.product_price_dollars_per_gal,
            productUnits,
            'product',
          )}`,
      },
      {
        Header: 'Yield Difference',
        accessor: (row) =>
          row.two_sided_yield_diff ? 'Two Sided' : 'One Sided',
      },
      {
        Header: 'False Positive Rate',
        accessor: (row) => `${row.false_positive_rate}%`,
      },
      {
        Header: 'Full Report',
        accessor: (row) =>
          reportIsReady ? (
            <div>
              <a
                href={row.report?.report_pdf?.signed_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download Report
              </a>
              {analysisDataAvailable && (
                <a
                  href={row.report?.report_zip?.signed_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginTop: '5px' }}
                >
                  Download Analysis Data
                </a>
              )}
            </div>
          ) : (
            <div className={styles['analysis-landing-table__tooltip']}>
              Not yet available
              <Tooltip
                label={
                  'FarmTest will email your analysis, which will also be downloadable here, once it is available.'
                }
              >
                <InfoIcon />
              </Tooltip>
            </div>
          ),
      },
    ];
    if (isRequested && !reportIsReady) {
      buttonColumns = [
        {
          Header: ' ',
          id: 'viewData',
          accessor: (row) => (
            <StepFlowButton
              destinationRoute={`/analyze-trial/analysis-setup-flow?id=${row.analysis_id}`}
              buttonText={'Report Requested'}
              isDisabled={true}
              disabledTooltip={
                'This analysis has been submitted. When the report is ready, it will be linked here.'
              }
            />
          ),
        },
      ];
    } else if (!isRequested) {
      buttonColumns = [
        {
          Header: ' ',
          id: 'editData',
          accessor: (row) => (
            <StepFlowButton
              destinationRoute={`/analyze-trial/analysis-setup-flow?id=${row.analysis_id}`}
              buttonText={'Edit'}
            />
          ),
        },
      ];
      if (canRequestReport) {
        buttonColumns.push(requestReportButton);
      }
    }
    return [...baseColumns, ...buttonColumns];
  }
};

const StepFlowButton = ({
  destinationRoute,
  buttonText,
  recordId,
  isDisabled,
  disabledTooltip,
}: StepFlowButtonProps) => {
  if (recordId) {
    destinationRoute = `${destinationRoute}?id=${recordId}`;
  }

  return (
    <Button
      action="link"
      variant="brand"
      to={destinationRoute}
      className={`${styles['analysis-landing-table__button']}`}
      isDisabled={isDisabled}
    >
      {isDisabled && disabledTooltip ? (
        <Tooltip label={disabledTooltip}>{buttonText}</Tooltip>
      ) : (
        buttonText
      )}
    </Button>
  );
};

const AnalysisLandingTable = ({
  data,
  columns,
  tableHeader,
  isLoading,
  imageUrl,
  imageIsNonSquare,
  isRequested,
  onRowSelect,
}: AnalysisLandingTableProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [imageLoading, setImageLoading] = useState(true);

  useEffect(() => {
    if (onRowSelect && data[activeIndex]) {
      onRowSelect(data[activeIndex]);
    }
  }, [activeIndex, data, onRowSelect]);

  const emptyTableData = [{ blankField: 'blank value' }];
  let imageStyle = imageIsNonSquare
    ? styles['analysis-landing-table__fields__image--non-square']
    : styles['analysis-landing-table__fields__image'];

  if (tableHeader === 'Trial Design' && imageUrl !== loadingImage) {
    imageStyle = styles['analysis-landing-table__fields__image--trial-design'];
  }

  return (
    <div className={`${styles['analysis-landing-table']}`}>
      <div
        className={styles['analysis-landing-table__fields']}
        data-testid={`analysis-landing-table-${tableHeader}`}
      >
        {imageUrl ? (
          <div className={imageStyle}>
            <ImagePreview
              imageUrl={imageUrl}
              isLoading={isLoading || imageLoading}
              onLoad={() => setImageLoading(false)}
              error={null}
              smallMode={true}
            />
          </div>
        ) : null}
        <div className={styles['analysis-landing-table__fields__container']}>
          <h1 className={styles['analysis-landing-table__fields__header']}>
            {tableHeader}
          </h1>
          {isLoading ? (
            <Spinner color="brand" size="28px" />
          ) : (
            <>
              <Table
                data={data.length > 0 ? data : emptyTableData}
                columns={columns}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
              />
              {['As-Applied Data', 'As-Planted Data'].includes(tableHeader) &&
              !isRequested ? (
                data.length === 0 ? (
                  <Link
                    to={Routes.noApplicationDataFlow}
                    className={styles['analysis-landing-link']}
                  >
                    Click here if you do not have
                    {` ${tableHeader.toLowerCase()} `}
                    for this field
                  </Link>
                ) : (
                  <Link
                    to={`${Routes.asAppliedFlow}?id=additional`}
                    className={styles['analysis-landing-link']}
                  >
                    Click here if you would like to upload an additional
                    {` ${tableHeader.split(' ')[0].toLowerCase()} `} pass for
                    this field
                  </Link>
                )
              ) : null}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const AnalysisLandingContent = ({
  trialDesign,
  analyses,
  fieldResults,
  trialDesignIsLoading,
  analysesIsLoading,
  fieldResultsIsLoading,
}: AnalysisLandingContentProps) => {
  // this component is constructed with the idea that we will eventually support multiple analyses
  // and perhaps even multiple as-applied/yield data sets per trial. However, as of right now, key
  // elements, such as the preview images, report request handling, and the create/edit buttons,
  // only support single analyses and single yield data sets per trial. Multiple as-applied files
  // are now supported and that can be used to help expand functionality to yield and analysis.
  const [requestReport, { isLoading }] = useRequestReportMutation();
  const [fieldResultsType, setFieldResultsType] = useState('as-planted');
  const [requestReportButton, setRequestReportButton] = useState<Column<any>>(
    {} as Column<any>,
  );
  const [selectedImageUrl, setSelectedImageUrl] = useState<
    string | typeof notYetProvided
  >(notYetProvided);
  const [fieldResultId, setFieldResultId] = useState<string | undefined>(
    undefined,
  );
  const { data: designImage, isLoading: designImageIsLoading } =
    useFetchDesignImageQuery(trialDesign?.shapefile_preview_url as string, {
      skip: !trialDesign?.shapefile_preview_url,
    });

  useEffect(() => {
    setRequestReportButton(
      getRequestReportButton(
        analyses[0]?.analysis_id || '',
        requestReport,
        isLoading,
      ),
    );
  }, [analyses, requestReport, isLoading]);

  useEffect(() => {
    if (trialDesign) {
      if (trialDesign.planning_trial_type === 'Planting') {
        setFieldResultsType('as-planted');
      } else {
        setFieldResultsType('as-applied');
      }
    }
  }, [trialDesign, setFieldResultsType]);

  const handleRowSelect = (row: FieldData) => {
    setSelectedImageUrl(
      row.scaled_preview_image?.signed_url ||
        row.raw_preview_image?.signed_url ||
        notYetProvided,
    );
  };

  const asAppliedData = fieldResults
    .filter((x) => x.as_applied_data !== undefined)
    .map(
      (x) =>
        ({
          ...x.as_applied_data,
          field_result_id: x?.field_result_id,
          heading_units: 'Degrees',
        } as FieldData),
    );
  const yieldData = fieldResults
    .filter((x) => x.yield_data !== undefined)
    .map(
      (x) =>
        ({
          ...x.yield_data,
          field_result_id: x?.field_result_id,
          heading_units: 'Degrees',
        } as FieldData),
    );
  const isRequested = analyses[0]?.ready_for_analysis || false;
  const canRequestReport: boolean =
    // currently, checks that we have not requested yet, we have an analysis, all as-applied results
    // have rate columns, and at least one field result is not additional and has a yield rate
    // TODO: this needs to be a comprehensive validation that all fields we need are completely filled
    !isRequested &&
    analyses[0]?.analysis_id !== undefined &&
    fieldResults.every(
      (result) => result.as_applied_data?.rate_column !== undefined,
    ) &&
    fieldResults.some(
      (result) =>
        (result.is_additional === undefined || !result.is_additional) &&
        result.yield_data?.rate_column !== undefined,
    );

  const reportIsReady = analyses[0]?.report?.is_approved || false;
  const analysisDataAvailable =
    analyses[0]?.report?.report_zip?.signed_url !== undefined;
  const reportPreviewUrl = reportIsReady
    ? analyses[0]?.report?.preview_image?.signed_url
    : notYetGenerated;

  useEffect(() => {
    // find the field result which is not additional, i.e. is the primary, and get its iD
    const id =
      fieldResults.length === 1
        ? fieldResults[0]?.field_result_id
        : fieldResults.find(
            (result) =>
              result.is_additional === undefined || !result.is_additional,
          )?.field_result_id;
    setFieldResultId(id);
  }, [fieldResults]);

  const application_units = fieldResults[0]?.as_applied_data?.rate_units
    ? fieldResults[0].as_applied_data.rate_units?.split('/')[0]
    : trialDesign?.application_units
    ? trialDesign?.application_units?.split('/')[0]
    : null;

  return (
    <div data-testid="analysis-landing-content">
      <AnalysisLandingTable
        data={trialDesign ? [trialDesign] : []}
        columns={getTrialColumns(application_units)}
        tableHeader="Trial Design"
        imageUrl={designImage?.image || loadingImage}
        isLoading={trialDesignIsLoading || designImageIsLoading}
        isRequested={isRequested}
      />
      <AnalysisLandingTable
        data={asAppliedData}
        columns={getFieldResultColumns(
          asAppliedData,
          fieldResultsType,
          Routes.asAppliedFlow,
          fieldResultId,
          isRequested,
        )}
        tableHeader={`${titleCase(fieldResultsType)} Data`}
        imageUrl={selectedImageUrl} // Use the selected image URL
        isLoading={fieldResultsIsLoading}
        isRequested={isRequested}
        onRowSelect={handleRowSelect} // Pass the row select handler
      />
      <AnalysisLandingTable
        data={yieldData}
        columns={getFieldResultColumns(
          yieldData,
          'yield',
          Routes.yieldDataFlow,
          fieldResultId,
          isRequested,
        )}
        tableHeader="Yield Data"
        imageUrl={
          yieldData[0]?.scaled_preview_image?.signed_url ||
          yieldData[0]?.raw_preview_image?.signed_url ||
          notYetProvided
        }
        isLoading={fieldResultsIsLoading}
        isRequested={isRequested}
      />
      <AnalysisLandingTable
        data={analyses}
        columns={getAnalysisColumns(
          analyses,
          fieldResults?.[0]?.yield_data?.rate_units,
          fieldResults?.[0]?.as_applied_data?.rate_units,
          analyses[0]?.analysis_crop || trialDesign?.crop || 'crop',
          requestReportButton,
          isRequested,
          canRequestReport,
          reportIsReady,
          analysisDataAvailable,
        )}
        tableHeader="Analysis"
        imageUrl={reportPreviewUrl}
        isLoading={analysesIsLoading}
        // if the report is ready, the preview image will be non-square
        imageIsNonSquare={reportIsReady}
        isRequested={isRequested}
      />
    </div>
  );
};

export default AnalysisLandingContent;
